import React, { useState } from 'react'
import { Cron } from 'react-js-cron';
import 'react-js-cron/dist/styles.css'
import CodeEditor from '@uiw/react-textarea-code-editor';
import { useStateContext } from '../../contexts/ContextProvider';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { CiCircleRemove } from 'react-icons/ci';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Tooltip } from 'antd';

const SUPPORTED_JAVA_VERSIONS = [
    'JAVA_11', 'JAVA_21'
];

const UseCaseBuilder = ({
    _useCase,
    onSubmit,
    namePlaceholder,
    descriptionPlaceholder,
    frequencyPlaceholder,
    editHeading,
    createHeading,
    emailForAlertPlaceholder,
    inputArgsPlaceholder,
    targetUptimePlaceholder,
    successOutputRegexPlaceholder
}) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const { currentColor } = useStateContext();
    const [targetUptimeValidation, setTargetUptimeValidation] = useState("")
    const [useCaseBuilder, setUseCaseBuilder] = useState({
        frequency: _useCase?.frequency || '0 */10 * ? * *',
        name: _useCase?.name || '',
        // script: _useCase?.script || `public class HelloWorld{\npublic static void main(String []args){\n System.out.println("Hello, World!");\n }\n}`,
        id: _useCase?.id || '',
        description: _useCase?.description || "",
        inputArgs: _useCase?.inputArgs || "",
        successOutputRegex: _useCase?.successOutputRegex || "",
        targetUptime: _useCase?.targetUptime || "",
        mailsForAlerts: _useCase?.mailsForAlerts || "",
        javaVersion: _useCase?.javaVersion || "JAVA_11",
    });
    var isEditing = false;

    if (_useCase) {
        isEditing = true;
    }

    const showToast = (toastMsg) => {
        toast(toastMsg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const handleSubmit = (event) => {
        console.log("submitted",
            useCaseBuilder
        );
        axios.post(`${API_ENDPOINT}/api/use-case`, useCaseBuilder)
            .then((response) => {
                console.log("Successfully added new Use Case!");
                let toastMsg = `${isEditing ? "Updated" : 'Created'} Use Case`
                showToast(toastMsg);
                if (onSubmit) {
                    onSubmit();
                }
            }).catch((err) => {
                console.log(err);
                showToast(err.response.data.message);
                // onSubmit();
            }
            )
    };
    const handleEnterEmail = (event) => {
        if (event.key === 'Enter') {
            const newEmail = event.target.value.trim(); // Trim whitespace
            // Check if newEmail is a valid email address
            const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (re.test(newEmail)) {
                let emails = [];
                if (useCaseBuilder?.mailsForAlerts) {
                    emails = [...useCaseBuilder.mailsForAlerts.split(',').filter(email => email !== '')]; // Filter out empty strings
                    if (!emails.includes(newEmail)) {
                        emails.push(newEmail);
                    }
                } else {
                    emails = [newEmail];
                }
                setUseCaseBuilder({
                    ...useCaseBuilder,
                    mailsForAlerts: emails.join(","),
                });
                event.target.value = ''; // Clear the input field
            }
        }
    };

    const handleRemoveEmail = (email) => {
        if (useCaseBuilder.mailsForAlerts) {
            const emails = new Set([...useCaseBuilder.mailsForAlerts.split(',')]);
            emails.delete(email);
            setUseCaseBuilder({
                ...useCaseBuilder,
                mailsForAlerts: Array.from(emails).join(","),
            });
        }
    };

    const handleTargetUptimeChange = (event) => {
        let { value, min, max } = event.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));

        if (value === '.' || /^\d*\.?\d*$/.test(value)) {
            setUseCaseBuilder({ ...useCaseBuilder, targetUptime: value });
            setTargetUptimeValidation("");
            return; // Stop further validation on partial input
        }
        const numericValue = parseFloat(value);
        if (numericValue >= 0 && numericValue <= 100 && /^[0-9]*\.?[0-9]+$/.test(value)) {
            setUseCaseBuilder({ ...useCaseBuilder, targetUptime: value });
            setTargetUptimeValidation("");
        } else {
            showToast("Invalid input. Only numbers between 0 and 100 are allowed.");
            setTargetUptimeValidation("Invalid input. Only numbers between 0 and 100 are allowed.");
            // Only reset targetUptime if the input is invalid
            if (value !== "") {
                setUseCaseBuilder({ ...useCaseBuilder, targetUptime: "" });
            }
            console.error("Invalid input. Only numbers between 0 and 100 are allowed.");
        }
    };

    const handleJavaVersionChange = (event) => {
        setUseCaseBuilder({ ...useCaseBuilder, javaVersion: event.target.value });
    };


    const heading = isEditing ? editHeading : createHeading || 'Use Case Builder';

    return (
        <>
            <div className="font-bold">{heading}</div>
            <div>
                <div className='p-2'>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <input
                            type="text"
                            value={useCaseBuilder.name}
                            onChange={(event) => { setUseCaseBuilder({ ...useCaseBuilder, name: event.target.value }) }}
                            placeholder={namePlaceholder ?? "Enter Use Case Name"}
                            className={"ml-2 mt-1 block w-80 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"} // add a class for styling the input field when there's an error
                        />
                    </div>

                    {/* <div className='p-3'>
                        <CodeEditor
                            value={useCaseBuilder.script || ''}
                            language="java"
                            placeholder="Please enter Java code."
                            onChange={(evn) => {
                                setUseCaseBuilder({ ...useCaseBuilder, script: evn.target.value });
                            }}
                            padding={15}
                            style={{
                                backgroundColor: "#f5f5f5",
                                fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                                minHeight: 300,
                                borderRadius: 10,
                            }}
                        />
                    </div> */}
                </div>

                <div className='p-2'>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <input
                            type="text"
                            value={useCaseBuilder.description}
                            onChange={(event) => { setUseCaseBuilder({ ...useCaseBuilder, description: event.target.value }) }}
                            placeholder={descriptionPlaceholder ?? "Enter Use Case Description"}
                            className={"ml-2 mt-1 block w-80 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"} // add a class for styling the input field when there's an error
                        />
                    </div>
                </div>

                <div className='p-2'>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <input
                            type="text"
                            value={useCaseBuilder.inputArgs}
                            onChange={(event) => { setUseCaseBuilder({ ...useCaseBuilder, inputArgs: event.target.value }) }}
                            placeholder={inputArgsPlaceholder ?? "Program Argument"}
                            className={"ml-2 mt-1 block w-80 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"} // add a class for styling the input field when there's an error
                        />
                    </div>
                </div>

                <div className='p-2'>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Tooltip placement='right' title={successOutputRegexPlaceholder}>
                            <input
                                type="text"
                                value={useCaseBuilder.successOutputRegex}
                                // title={successOutputRegexPlaceholder}
                                onChange={(event) => { setUseCaseBuilder({ ...useCaseBuilder, successOutputRegex: event.target.value }) }}
                                placeholder={successOutputRegexPlaceholder ?? "Enter Success Output Regex"}
                                className={"ml-2 mt-1 block w-80 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"} // add a class for styling the input field when there's an error
                            />
                        </Tooltip>
                    </div>
                </div>

                <div className='p-2'>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Tooltip placement='right' title={targetUptimeValidation}>
                            <input
                                type="number"
                                min="0"
                                max="100"
                                value={useCaseBuilder.targetUptime}
                                onChange={handleTargetUptimeChange}
                                placeholder={targetUptimePlaceholder ?? "Target Uptime"}
                                className={"ml-2 mt-1 block w-80 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"} // add a class for styling the input field when there's an error
                            />
                        </Tooltip>

                    </div>
                </div>

                {/* <Cron
                    value={useCaseBuilder.frequency}
                    // onChange={handleCronChange}
                    setValue={(value) => {
                        console.log('frequency', value);
                        setUseCaseBuilder({ ...useCaseBuilder, frequency: value });
                    }}
                /> */}

                <div className='p-2'>
                    <Tooltip placement='topRight' title="For cron example refer link below">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <input
                                type="text"
                                // title="For cron example refer link below"
                                value={useCaseBuilder.frequency}
                                onChange={(event) => { setUseCaseBuilder({ ...useCaseBuilder, frequency: event.target.value }) }}
                                placeholder={frequencyPlaceholder ?? "Enter Run Frequency"}
                                className={"ml-2 mt-1 block w-80 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"} // add a class for styling the input field when there's an error
                            />
                        </div>
                    </Tooltip>
                    <div className="flex justify-end items-center text-sm text-gray-600 ml-2">
                        <AiOutlineInfoCircle className="mr-1 text-blue-500" />
                        <span>
                            For cron example refer this{' '}
                            <a
                                href="https://www.freeformatter.com/cron-expression-generator-quartz.html"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:underline"
                            >
                                link
                            </a>
                        </span>
                    </div>
                </div>

                <div className='p-2'>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {/* <input
                            type="email"
                            value={useCaseBuilder.mailsForAlerts}
                            onChange={(event) => { setUseCaseBuilder({ ...useCaseBuilder, mailsForAlerts: event.target.value }) }}
                            placeholder={emailForAlertPlaceholder ?? "Get an alert when a script failed execution"}
                            className={"ml-2 mt-1 block w-80 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"} // add a class for styling the input field when there's an error
                        /> */}
                        <input
                            type="email"
                            // value={useCaseBuilder?.mailsForAlerts}
                            name="email"
                            title="Type and press Enter to add an email"
                            className={"ml-2 mt-1 block w-80 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"} // add a class for styling the input field when there's an error
                            placeholder={emailForAlertPlaceholder ?? "Get an alert when a script failed execution"}
                            onKeyDown={handleEnterEmail}
                        />
                    </div>
                    <div class="grid-cols-3 gap-x-16 gap-y-2 overflow-auto max-h-24 mt-2 block">
                        {useCaseBuilder.mailsForAlerts && (
                            useCaseBuilder.mailsForAlerts?.split(',').map((email, index) => (
                                <>
                                    <div
                                        style={{ borderColor: currentColor }}
                                        className="rounded border-solid border-2 p-2 inline-flex whitespace-nowrap min-w-fit mr-2 mb-1 max-w-fit"
                                        key={index} // Add key prop for performance
                                    >
                                        <h1 style={{ color: currentColor }}>{email}</h1>
                                        <button
                                            onClick={() => handleRemoveEmail(email)}
                                            className="ml-1 mb-1 p-1 rounded hover:bg-red-600 hover:text-white focus:outline-none"
                                        >
                                            <CiCircleRemove />
                                        </button>
                                    </div>
                                </>
                            ))
                        )}

                        {/* cron for schedule */}
                    </div>
                    {/* Add dropdown for selecting java version */}
                    <div className='p-2'>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <select value={useCaseBuilder.javaVersion} onChange={handleJavaVersionChange} className="ml-2 mt-1 block w-80 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                {SUPPORTED_JAVA_VERSIONS.map((version, index) => (
                                    <option key={version} value={version}>
                                        {version}
                                    </option>
                                ))}

                            </select>
                        </div>
                    </div>
                </div>

                <button
                    style={{ backgroundColor: currentColor }}
                    className=" text-white font-semibold py-2 px-4 rounded mt-4 mx-4 my-4"
                    onClick={handleSubmit}
                >{isEditing ? "Update" : "Save"}
                </button>


            </div>
            {/* <ToastContainer /> */}

        </>
    )
}

export default UseCaseBuilder