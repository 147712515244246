import React, { useEffect, useState } from 'react'
import { useStateContext } from '../../contexts/ContextProvider'
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Sort, Filter, Group, Inject, Search, Toolbar, Selection, ColumnChooser } from '@syncfusion/ej2-react-grids';
import MenuDropdown from '../../components/MenuDropdown';
import ModalComponent from '../../components/ModalComponent';
import { FiEdit } from 'react-icons/fi';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import UseCaseBuilder from './UseCaseBuilder';
import axios from 'axios';
import FileUploader from '../../components/FileUploader';
import { FaCloudDownloadAlt, FaCloudUploadAlt } from "react-icons/fa";
import { TbAlertTriangleFilled } from "react-icons/tb";
import { FaRegPauseCircle, FaRegPlayCircle } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import { Tooltip } from 'antd';


const showToast = (toastMsg) => {
    toast(toastMsg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
}

const UseCases = ({ pageSize, onUserSelected }) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    pageSize = pageSize === undefined ? 15 : pageSize;
    const { currentColor } = useStateContext();
    const toolbarOptions = ['Search', 'ColumnChooser'];
    const [useCases, setUseCases] = useState([]);
    const [selectedUseCase, setSelectedUseCase] = useState(undefined);
    const [isFileUploaded, setIsFileUploaded] = useState(false)
    const [useCaseIDofUploadingJar, setUseCaseIDofUploadingJar] = useState(undefined)
    const [showUseCasBuilderModal, setShowUseCaseBuilderModal] = useState(false)
    const [showFileUploaderModal, setShowFileUploaderModal] = useState(false)
    const [grid, setGrid] = useState();


    const fetchUseCases = () => {
        axios.get(`${API_ENDPOINT}/api/use-case/list`)
            .then((response) => setUseCases(response.data.data))
            .catch((error) => console.error('Error fetching data:', error));
    }

    useEffect(() => {
        fetchUseCases();
    }, [])


    const created = () => {
        document.getElementById(grid.element.id + "_searchbar").addEventListener('keyup', (event) => {
            grid.search(event.target.value);
        });
    };


    const handleEditUseCase = (props) => {
        console.log("edit", props);
        setSelectedUseCase(props)
        setShowUseCaseBuilderModal(true)
    }

    const handleDeleteUseCase = (props) => {
        console.log("delete", props);
        if (window.confirm(`Are you sure you want to delete the ${props.name} Use Case?`)) {
            axios.delete(`${API_ENDPOINT}/api/use-case/${props.id}`)
                .then((response) => {
                    showToast("Successfully deleted use case!");
                    fetchUseCases();
                })
                .catch((error) => {
                    console.error(error);
                    showToast(error.response.data.message);
                });
        }
    }

    // const handleDownloadFile = (props) => {
    //     alert("download")
    // }
    const handleUploadFile = (props) => {
        setShowFileUploaderModal(true)
        setUseCaseIDofUploadingJar(props.id)
        console.log('useCaseIDofUploadingJar', useCaseIDofUploadingJar)
    }

    const uploadJar = (file) => {
        const formData = new FormData();
        formData.append('file', file);


        axios.post(`${API_ENDPOINT}/api/use-case/${useCaseIDofUploadingJar}/jar/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        )
            .then((response) => {
                console.log("Successfully uploaded jar!");
                showToast("Successfully uploaded jar!");
                fetchUseCases()
                setShowFileUploaderModal(false)
            }
            )

    }

    const handlePauseUseCaseRun = (props) => {
        axios.post(`${API_ENDPOINT}/api/use-case/pause`, props)
            .then((response) => {
                console.log("Successfully Paused Use Case!");
                showToast("Successfully Paused Use Case!");
                fetchUseCases();
            }).catch((err) => {
                console.log(err);
                showToast(err.response);
            })

    }

    const handleResumeUseCaseRun = (props) => {
        axios.post(`${API_ENDPOINT}/api/use-case/${props.id}/resume`)
            .then((response) => {
                console.log("Successfully Resumed Use Case!");
                showToast("Successfully Resumed Use Case!");
                fetchUseCases();
            }).catch((err) => {
                console.log(err);
                showToast(err.response);
            })
    }

    const fileUploadComp = (
        <>
            <FileUploader accept=".jar"
                onUpload={(file) => {
                    setIsFileUploaded(true)
                    uploadJar(file)
                }}
                heading="Upload Jar" />
        </>
    );


    const useCaseComp = (
        <>
            <UseCaseBuilder _useCase={selectedUseCase} onSubmit={() => {
                setShowUseCaseBuilderModal(false);
                // setShowFileUploaderModal(true);
                fetchUseCases();
            }}
                namePlaceholder={"Title"}
                descriptionPlaceholder={"Description"}
                frequencyPlaceholder={"Run Frequency"}
                editHeading={"Edit Use Case"}
                createHeading={"Create Use Case"}
                inputArgsPlaceholder={"Program Args"}
                targetUptimePlaceholder={"Target Uptime"}
                successOutputRegexPlaceholder={"This regex will be matched against the output of the use case to determine if the use case was successful."} />
        </>
    );

    const tooltipTemplate = (field) => {
        return (props) => (
            <Tooltip title={props[field]}>
                <span>{props[field]}</span>
            </Tooltip>
        )
    }


    return (
        <>
            <div className="p-6">
                <div className="flex">
                    <h2 className="text-2xl font-bold mb-4">Use Cases</h2>
                    <span className="justify-end right-11 absolute">
                        <MenuDropdown
                            options={[
                                {
                                    label: "Add Use Case",
                                    action: () => {
                                        setSelectedUseCase(undefined)
                                        setShowUseCaseBuilderModal(true);
                                    },
                                },

                            ]}
                        ></MenuDropdown>
                    </span>
                    <ModalComponent isOpen={showUseCasBuilderModal} onClose={() => setShowUseCaseBuilderModal(false)}
                        content={useCaseComp} minWidth="50%" />
                    <ModalComponent isOpen={showFileUploaderModal} onClose={() => setShowFileUploaderModal(false)}
                        content={fileUploadComp} minWidth={"50%"} />
                </div>
            </div>


            <div className="p-6">
                <GridComponent
                    dataSource={useCases}
                    allowPaging={true} pageSettings={{ pageSize: pageSize }}
                    ref={g => setGrid(g)}
                    toolbar={toolbarOptions}
                    created={created}
                    selectionSettings={{ allow: true, mode: "Cell" }}
                    showColumnChooser={true}>

                    <ColumnsDirective>
                        <ColumnDirective
                            field="name"
                            headerText="Use Case Name"
                            width="25%"
                            template={(props) => (
                                <div className="flex items-center">
                                    {!props.jar && (
                                        <Tooltip title="No Jar Uploaded">
                                            <TbAlertTriangleFilled className="mr-1 text-red-500" />
                                        </Tooltip>
                                    )}
                                    {tooltipTemplate("name")(props)}
                                    {/* {props.name} */}
                                </div>
                            )}
                        />
                        <ColumnDirective
                            field="description"
                            headerText="Description"
                            width="25%"
                            template={tooltipTemplate("description")}
                        />

                        <ColumnDirective
                            field="frequency"
                            headerText="Frequency"
                            width="25%"
                            template={tooltipTemplate("frequency")}
                        />
                        <ColumnDirective
                            field="version"
                            headerText="Version"
                            width="15%"
                            visible={false}
                        />

                        <ColumnDirective headerText="Actions" width="25%" template={(props) => (
                            <span>
                                <Tooltip title='Edit'>
                                    <button
                                        className="bg-green-500 text-white p-2 rounded hover:bg-green-600 focus:outline-none mr-2"
                                        // title='Edit'
                                        onClick={() => handleEditUseCase(props)}
                                    >
                                        <FiEdit></FiEdit>
                                    </button>
                                </Tooltip>

                                <Tooltip title='Delete'>
                                    <button
                                        className="bg-red-500 text-white p-2 rounded hover:bg-red-600 focus:outline-none mr-2"
                                        // title='Delete'
                                        onClick={() => handleDeleteUseCase(props)}
                                    >
                                        <MdOutlineDeleteOutline></MdOutlineDeleteOutline>
                                    </button>
                                </Tooltip>

                                <Tooltip title='Upload Jar'>
                                    <button
                                        className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 focus:outline-none mr-2"
                                        // title='Upload Jar'
                                        onClick={() => handleUploadFile(props)}
                                    >
                                        <FaCloudUploadAlt />

                                    </button>
                                </Tooltip>

                                {(props.jar && props.isActive) &&
                                    <Tooltip title='Pause Run'>
                                        <button
                                            className="bg-blue-500 text-white p-2 rounded hover:bg-purple-600 focus:outline-none mr-2"
                                            // title='Pause Run'
                                            onClick={() => handlePauseUseCaseRun(props)}
                                        >
                                            <FaRegPauseCircle />

                                        </button>
                                    </Tooltip>
                                }

                                {(props.jar && !props.isActive) &&
                                    <Tooltip title='Resume Run'>
                                        <button
                                            className="bg-purple-500 text-white p-2 rounded hover:bg-blue-600 focus:outline-none mr-2"
                                            // title='Play Run'
                                            onClick={() => handleResumeUseCaseRun(props)}
                                        >
                                            <FaRegPlayCircle />

                                        </button>
                                    </Tooltip>
                                }

                                {/* <button
                                    className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 focus:outline-none mr-2"
                                    title='Download File'
                                    onClick={() => handleDownloadFile(props)}
                                >
                                    <FaCloudDownloadAlt />

                                </button> */}


                            </span>
                        )}></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Group, Search, Toolbar, Selection, ColumnChooser]} />
                </GridComponent>
            </div>
            <ToastContainer />

        </>
    )
}

export default UseCases