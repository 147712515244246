import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import DashboardTimeSeriesGraph from './DashboardTimeSeriesGraph';
import axios from 'axios';
import Loader from '../../components/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { SiShopware } from 'react-icons/si';
import verlakeLogo from './../../data/images/exotel-logo.png';


const PublicDashboard = () => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const { dashboardId } = useParams();
    const [dashboardRunResult, setDashboardRunResult] = useState(undefined)
    const [errorMessage, setErrorMessage] = useState(undefined)

    const showToast = (toastMsg) => {
        toast(toastMsg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    };
    useEffect(() => {
        console.log("useEffect logged")
        axios.get(`${API_ENDPOINT}/public/dashboard/${dashboardId}/report`)
            .then((response) => {
                console.log("response.report", response.data.data.report)
                setDashboardRunResult(response.data.data)
            }).catch((err) => {
                // console.log(err);
                console.log("Error fetching dashboard run result", err.response.data.message);
                showToast(err.response.data.message);
                setErrorMessage(err.response.data.message)
            }
            )

    }, []);

    const errorContent = (
        <div className="p-4 bg-gray-100">
            <div className="flex justify-center mb-6">
                <div className="bg-white p-4 rounded-lg shadow-md">
                    <p className="text-lg font-semibold mb-2 text-gray-700">{errorMessage}</p>
                </div>
            </div>
        </div>
    )


    return (
        <>
            <div className='flex flex-col'>
                <div className='sticky top-0 max-h-[10vh] bg-gray-200'>
                    <span className="flex flex-start">
                        <img src={verlakeLogo} alt="Exotel Logo" className="ml-4 w-32 h-16 m-1 mt-4" />

                    </span>
                </div>

                <div className="max-h-[81vh]">

                    {/* PublicDashboard: {dashboardId} */}
                    {dashboardRunResult
                        ? <DashboardTimeSeriesGraph _dashboardData={dashboardRunResult} isPublic="true" />
                        : errorMessage
                            ? errorContent
                            : <Loader />}
                    <ToastContainer />
                </div>

                <div className='sticky bottom-0 bg-gray-200'>
                    <span className="flex flex-end bg-gray-200">

                        <Link to="/" className="items-center gap-3 mt-12 flex mr-1 tracking-tight absolute right-2">
                            <text>Powered by</text>
                            <SiShopware />
                            <h1 className='text-xl font-bold'>Verlake</h1>
                        </Link>
                    </span>
                </div>
            </div>

        </>


    );
}

export default PublicDashboard;