import React, { useCallback, useEffect, useState } from 'react'
import MenuDropdown from '../../components/MenuDropdown';
import ModalComponent from '../../components/ModalComponent';
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Search, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { FiCopy, FiEdit } from 'react-icons/fi';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { BsBrowserChrome } from "react-icons/bs";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import DashboardOrchestration from './DashboardOrchestration';
import DashboardTimeSeriesGraph from './DashboardTimeSeriesGraph';
import { ImEarth } from "react-icons/im";
// import ToggleSwitch from '../../components/ToggleSwitch';
import { Switch, Tooltip } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';



const Dashboards = () => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const [dashboards, setDashboards] = useState([]);
    const [isCreateDashboardModalOpen, setIsCreateDashboardModalOpen] = useState(false);
    const [dashboardToEdit, setDashboardToEdit] = useState(undefined);
    const [dashboardRunResult, setDashboardRunResult] = useState({})
    const [showModalTimeSeries, setShowModalTimeSeries] = useState(false);
    const [isPublicDashboard, setIsPublicDashboard] = useState(false)

    useEffect(() => {
        if (dashboardRunResult.dashboard && dashboardRunResult.dashboard.isPublic) {
            setIsPublicDashboard(true);
        } else {
            setIsPublicDashboard(false);
        }
    }, [dashboardRunResult]);

    const showToast = (toastMsg) => {
        toast(toastMsg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const toolbarOptions = ['Search'];

    const options = [
        {
            label: 'Create Dashboard',
            action: () => {
                setDashboardToEdit(undefined)
                setIsCreateDashboardModalOpen(true);
            }
        }
    ];

    const fetchDashboardRunResult = (dashboardId) => {
        axios.get(`${API_ENDPOINT}/api/dashboard/${dashboardId}/report`)
            .then((response) => {
                console.log("response run result", response.data.data)
                setDashboardRunResult(response.data.data)
            }).catch((err) => {
                console.log(err);
                showToast(err.response.data.data);
            }
            )
    }

    const closeCreateDashboardModal = () => {
        setIsCreateDashboardModalOpen(false);
    };

    const fetchDashboard = (event) => {
        axios.post(`${API_ENDPOINT}/api/dashboard/list`, {})
            .then((response) => {
                setDashboards(response.data.data)
                console.log("dashboard list", response.data.data);

            }).catch((err) => {
                console.log(err);
                // showToast(err.response.data.data);
            }
            )
    }

    const handleToggleChange = ({ id, newToggleState }) => {
        setIsPublicDashboard(newToggleState)
        axios.post(`${API_ENDPOINT}/api/dashboard/visibility/${id}?visible=${newToggleState}`, {})
            .then((response) => {
                setDashboards(response.data.data)
                console.log("dashboard list", response);

            }).catch((err) => {
                console.log(err);
                // showToast(err.response.data.data);
            }
            )

        console.log("Toggle state changed:", newToggleState);

    };

    const timeSeriesContent = (
        <>
            <div className='bg-gray-200 justify-end rounded-lg pr-5'>

                <div className='flex justify-end mr-2'>
                    <AiOutlineInfoCircle className="mr-1 mt-0.5 text-blue-500" />
                    <span className='text-sm text-gray-800 mr-1'>Enable Public Access to this Dashboard</span>
                    <Switch className="mt-1" size="small" defaultChecked={isPublicDashboard} onChange={(checked) => handleToggleChange({ id: dashboardRunResult.dashboard.id, newToggleState: checked })} />
                </div>

                {isPublicDashboard && (
                    <div className=' flex justify-end mr-1 text-sm'>
                        <a
                            href={`${window.location.origin}/dashboard/${dashboardRunResult.dashboard.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {`${window.location.origin}/dashboard/${dashboardRunResult.dashboard.id}`}
                        </a>
                        <Tooltip title='Copy to Clipboard' placement="bottomLeft">
                            <FiCopy

                                className="ml-2 text-gray-500 cursor-pointer mt-1"
                                onClick={() => {
                                    navigator.clipboard.writeText(`${window.location.origin}/dashboard/${dashboardRunResult.dashboard.id}`);
                                    showToast("Copied to clipboard!");
                                }}
                            />
                        </Tooltip>
                    </div>
                )}
            </div>

            <DashboardTimeSeriesGraph _dashboardData={dashboardRunResult} />
        </>
    )


    const createDashboardModalContent = (
        <>

            <DashboardOrchestration dashboard={dashboardToEdit} onDashboardCreation={() => { fetchDashboard.apply(); setIsCreateDashboardModalOpen(false) }} />
        </>
    );

    useEffect(() => {
        fetchDashboard();
    }, []);



    const handleBrowseDashboard = (props) => {
        console.log('show detail: ', props);
        fetchDashboardRunResult(props.id);
        setTimeout(() => {
            setShowModalTimeSeries(true);
        }, 600);
    }

    const handleDeleteDashboard = (props) => {
        console.log("delete", props);
        if (window.confirm(`Are you sure you want to delete the ${props.title} Dashboard?`)) {
            axios.delete(`${API_ENDPOINT}/api/dashboard/${props.id}`)
                .then((response) => {
                    showToast(`Successfully deleted ${props.title} Dashboard!`);
                    fetchDashboard();
                })
                .catch((error) => {
                    console.error(error);
                    // showToast(error.response.data.message);
                });
        }
    }

    const handleEditDashboard = (dashboard) => {
        console.log(dashboard)
        setDashboardToEdit(dashboard);
        setIsCreateDashboardModalOpen(true);
    }

    const tooltipTemplate = (field) => {
        return (props) => (
            <Tooltip title={props[field]}>
                <span>{props[field]}</span>
            </Tooltip>
        )
    }


    return (
        <>
            <div className="p-6">

                {/* Dropdown */}
                <div className="flex">
                    <h2 className="text-2xl font-bold mb-4">Dashboards</h2>
                    <span className="justify-end right-11 absolute">
                        <MenuDropdown options={options}></MenuDropdown>
                    </span>
                    <ModalComponent isOpen={isCreateDashboardModalOpen} onClose={closeCreateDashboardModal}
                        content={createDashboardModalContent}
                    />
                </div>
                <GridComponent dataSource={dashboards} allowPaging={true} pageSettings={{ pageSize: 15 }} toolbar={toolbarOptions}>
                    <ColumnsDirective>
                        <ColumnDirective
                            field="title"
                            headerText="Title"
                            width="33%"
                            template={(props) => (
                                <div className="flex items-center">
                                    {tooltipTemplate('title')(props)}
                                    {props?.isPublic && (
                                        <Tooltip title="This Dashboard is Public" placement='topLeft'>
                                            <ImEarth className="mr-1 ml-1 text-blue-500" />
                                        </Tooltip>
                                    )}


                                </div>
                            )}
                        />
                        <ColumnDirective
                            field="description"
                            headerText="Description"
                            width="33%"
                            template={tooltipTemplate("description")} />

                        {/* <ColumnDirective field="useCases" headerText="Use Cases" width="100" /> */}
                        <ColumnDirective headerText="Actions" width="33%" template={(props) => (
                            <span>
                                <Tooltip title="Edit">
                                    <button
                                        className="bg-green-500 text-white p-2 rounded hover:bg-green-600 focus:outline-none mr-2"
                                        // title='Edit'
                                        onClick={() => handleEditDashboard(props)}
                                    >
                                        <FiEdit></FiEdit>
                                    </button>
                                </Tooltip>

                                <Tooltip title="Browse">
                                    <button
                                        className="bg-purple-500 text-white p-2 rounded hover:bg-purple-600 focus:outline-none mr-2"
                                        title='Browse'
                                        onClick={() => handleBrowseDashboard(props)}
                                    >
                                        <BsBrowserChrome />
                                    </button>
                                </Tooltip>

                                <Tooltip title="Delete">
                                    <button
                                        className="bg-red-500 text-white p-2 rounded hover:bg-red-600 focus:outline-none mr-2"
                                        title='Delete'
                                        onClick={() => handleDeleteDashboard(props)}
                                    >
                                        <MdOutlineDeleteOutline></MdOutlineDeleteOutline>
                                    </button>
                                </Tooltip>
                            </span>
                        )}></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Group, Search, Toolbar]} />
                </GridComponent>
                <ModalComponent isOpen={showModalTimeSeries} onClose={() => setShowModalTimeSeries(false)}
                    content={timeSeriesContent} />
                <ToastContainer />
            </div>
        </>
    )
}

export default Dashboards